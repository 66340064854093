import {
  BLOCKS_CLIENT,
  BLOCKS_CLIENT_ETH,
  BLOCKS_CLIENT_PULSE,
  INFO_CLIENT,
  INFO_CLIENT_ETH,
  INFO_CLIENT_PULSE
} from 'config/constants/endpoints'
import { infoClientETH, infoClient, infoStableSwapClient, infoClientPulse } from 'utils/graphql'

import { ChainId } from '@pancakeswap/sdk'
import {
  ETH_TOKEN_BLACKLIST,
  PCS_ETH_START,
  PCS_V2_START,
  TOKEN_BLACKLIST,
  BSC_TOKEN_WHITELIST,
  ETH_TOKEN_WHITELIST,
  PULSEX_V2_START,
  PULSE_TOKEN_BLACKLIST,
  PULSE_TOKEN_WHITELIST,
} from 'config/constants/info'

export type MultiChainName = 'BSC' | 'ETH' | 'PULSE'

export const multiChainQueryMainToken = {
  BSC: 'BNB',
  ETH: 'ETH',
  // PULSE: 'PLS'
  PULSE: 'BNB' // We use BNB because subgraph has name of 'BNB' not 'PLS'
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT,
  ETH: BLOCKS_CLIENT_ETH,
  PULSE: BLOCKS_CLIENT_PULSE
}

export const multiChainStartTime = {
  BSC: PCS_V2_START,
  ETH: PCS_ETH_START,
  PULSE: PULSEX_V2_START
}

export const multiChainId = {
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  PULSE: ChainId.PULSE
}

export const multiChainPaths = {
  [ChainId.BSC]: '/bsc',
  [ChainId.ETHEREUM]: '/eth',
  [ChainId.PULSE]: '',
}

export const multiChainQueryClient = {
  BSC: infoClient,
  ETH: infoClientETH,
  PULSE: infoClientPulse
}

export const multiChainQueryEndPoint = {
  BSC: INFO_CLIENT,
  ETH: INFO_CLIENT_ETH,
  PULSE: INFO_CLIENT_PULSE
}

export const multiChainScan = {
  BSC: 'BscScan',
  ETH: 'EtherScan',
  PULSE: 'PulseScan'
}

export const multiChainTokenBlackList = {
  BSC: TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  PULSE: PULSE_TOKEN_BLACKLIST
}

export const multiChainTokenWhiteList = {
  BSC: BSC_TOKEN_WHITELIST,
  ETH: ETH_TOKEN_WHITELIST,
  PULSE: PULSE_TOKEN_WHITELIST
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
